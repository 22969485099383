import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/EN/LayoutEN'
import ServiceHeader from '../components/Services/ServiceOverview/ServiceHeader'
import './LanguageDetailPage.css'

const LanguageDetailPageTemplate = props => {
  return (
    <>
      <ServiceHeader
        title={props.title}
        backgroundImage={
          !!props.image.childImageSharp
            ? props.image.childImageSharp.fluid.src
            : props.image
        }
      />
      <div className="container">
        <div
          className="LanguageDetail--Content"
          dangerouslySetInnerHTML={{ __html: props.body }}
        ></div>
      </div>
    </>
  )
}

const LanguageDetailPage = ({ data: { page } }) => {
  return (
    <Layout
      meta={page.frontmatter.meta || false}
      title={page.frontmatter.title || false}
    >
      <LanguageDetailPageTemplate {...page.frontmatter} body={page.html} />
    </Layout>
  )
}

export default LanguageDetailPage

export const pageQuery = graphql`
  query LanguageDetailPageEN($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        subtitle
        image {
          childImageSharp {
            fluid(maxHeight: 200) {
              src
            }
          }
        }
      }
    }
  }
`
